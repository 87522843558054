<template>
  <div class="py-3 d-flex justify-content-center"
       style="background-color: #f2f2f2;    border-bottom: #428bca 10px solid;">
    <div class="w1180 bg-white p-4 my-4">
      <CasesHeader :c_title="info.title" :c_date="info.date"/>

      <div class="x12 text-big">
        <p>
          作为一名外资企业的高级管理人员，由于工作的关系，我经常去美国出差，也有很多美国的同事和朋友，所以对这个国家的各个方面都比较了解。
        </p>
        <p>
          近几年，看到越来越多的食品安全问题方面的报导，以及空气质量也越来越差，一到冬天感觉自己都离不开口罩。思来想去，我决定为自己及家人今后的生活提前作出准备。
        </p>
        <p>
          正好今年3月以后一直在家办公，工作也没有那么忙碌，我就开始在线上联系了几家移民中介，咨询了办理美国移民的通道和流程，包括需要的资金和花费的时间等等。最终，我选择了跟我聊得最投契的跃迁顾问胡先生帮我办理美国的绿卡。
        </p>
        <p>
          胡先生根据我们的家庭情况，向我推荐了前几年大热的EB5。他说目前EB5对于直投项目来说有个很大的优势—没有排期。这将为我大大缩短等待的时间成本，让我能够尽快拿到绿卡。因为我的孩子已经在读初中，我希望可以在高考之前全家搬到美国，这样孩子的学习压力没有那么大，家长也不用跟着焦虑，家庭氛围也能不那么抑郁。
        </p>
        <p>
          因此，办理时间短这一点正中我的下怀。我们很快就确定了投资方式，以我手上另一套小户型房产的出售所得作为资金来源，然后我去联系中介准备挂牌出售事宜，跃迁则帮我联系美国项目方确定投资标的。
        </p>
        <p>
          因为在家没什么事情，所以这一切很快都完成了准备工作，我也拿到了投资餐厅的详细介绍。确定一切无碍后，我就一边开始准备工作，一边催促中介帮我卖房。没想到疫情期间我的房子还挺受欢迎，可能是地段还不错吧，很快我就和下家完成了交易，拿到了钱款。随后，跃迁的文案指导我应该如何汇款，需要保留哪一些汇款凭证，一步一步都非常细心，看得出他们的经验非常丰富。
        </p>
        <p>
          终于，一切落定，上海也全面复工。我现在一边安心工作，一边等待着我的526审批，希望能早日通过。感谢跃迁的顾问和文案，他们的服务让我安心满意。

        </p>
      </div>

      <CasesFooter class="mt-5" :index="info.index"/>
    </div>
  </div>

</template>

<script>
import CasesHeader from "@/components/Cases/CasesHeader";
import CasesFooter from "@/components/Cases/CasesFooter";

export default {
  name: "Cases16",
  components: {CasesFooter, CasesHeader},
  data() {
    return {
      info: this.$route.meta,
    }
  }
}
</script>

<style scoped>
.aligncenter {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

a {
  color: #428bca;
  text-decoration: none;
}
</style>